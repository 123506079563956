@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');

.App{
  display: flex;
  align-items: center;
  font-family: 'Rubik', sans-serif;
}

.contenedorPublic{
  display: flex;
  justify-content: center;
  position: relative;
  background-color: #f0f0f0;
}

.containerNotFound {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 500px;
  height: 500px;
  border-color: #05000D;
  border: 1px solid;
  border-radius: 10px;
  margin: 0 auto;
  text-align: center;
  height: auto;
  padding: 1rem;
  box-shadow: rgba(255, 0, 0, 0.3) 8px 8px 0px;
}

.colorLink{
  color: blue;
  text-decoration: underline;
  padding: 1rem;
}

.formLogin{
  display: flex;
  color: #0D0D0D;
  /* border: 1px solid #0D0D0D; */
  background-color: transparent;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
}

.formCreateIncidence{
  display: flex;
  color: #0D0D0D;
  background-color: #4A4C59;
  padding: 10px;
  border-radius: 10px;
}

.drawerListItem{
  background-color: #FFFF;
}

.drawerListItem:hover{
  background-color: aquamarine;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Opcionalmente, ajusta la altura según tus necesidades */
}

.attemptModal {
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 10px;
  background-color: #f0f0f0;
  border-color: #05000D;
  color: #0D0D0D;
  box-shadow: #05000D 8px 8px 0px;
  overflow-y: auto;
  display: flex;
  max-width: 60%;
  max-height: 30%;
}

.titleCommonStyle{
  font-weight: "600";
  font-family: "Rubik"
}